/*
 * @Author: xingwanjiang
 * @Date: 2021-10-13 16:26:52
 * @LastEditors: xingwanjiang
 * @LastEditTime: 2022-03-08 17:37:13
 * @Description: 全局引入
 */
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// import dataV from '@jiaminghi/data-view';
import './utils/flexible' // flexible
// 引入全局css
import './assets/scss/style.scss';
// 按需引入vue-awesome图标
import 'vue-awesome/icons/chart-bar.js';
import 'vue-awesome/icons/chart-area.js';
import 'vue-awesome/icons/chart-pie.js';
import 'vue-awesome/icons/chart-line.js';
import 'vue-awesome/icons/align-left.js';
import Router from 'vue-router'

//引入echart
//4.x 引用方式
import echarts from 'echarts'
//5.x 引用方式为按需引用
//希望使用5.x版本的话,需要在package.json中更新版本号,并切换引用方式
//import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts
Vue.config.productionTip = false;
Vue.use(Router)
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
// 全局注册
Vue.use(ElementUI);
// Vue.component('icon', Icon);
// Vue.use(dataV);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');