/*
 * @Author: xingwanjiang
 * @Date: 2022-03-14 11:36:42
 * @LastEditors: WangPei
 * @LastEditTime: 2024-05-30 10:56:27
 * @Description: 存储用户信息
 */
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userInfo: {}
  },
  mutations: {
    SET_USERINFO: (state, userInfo) => {
      state.userInfo = userInfo
    }
  },
  actions: {
    setUserInfo({
      commit
    }, userInfo) {
      commit('SET_USERINFO', userInfo)
    }
  },
  modules: {

  }
})