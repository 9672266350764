/*
 * @Author: xingwanjiang
 * @Date: 2021-10-13 16:26:52
 * @LastEditors: xingwanjiang
 * @LastEditTime: 2022-03-08 17:40:55
 * @Description: 
 */
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'login',
    component: () => import('../views/login.vue')
  },
  {
    path: '/detail',
    name: 'detail',
    component: () => import('../views/detail.vue')
  }
]
const router = new VueRouter({
  mode: "history",
  routes
})

export default router